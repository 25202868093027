@import "../variables";
.introduction {
  padding: 7rem 0 16rem;
  // height: 40vh;
}

.about-intro-text {
  width: 52%;
  color: #ffffff;
  h2 {
    font-weight: 600;
    font-size: 48px;
  }

  p {
    margin-top: 2rem;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
  }
}

.our-company {
  padding: 10rem 0 4rem;

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.2px;

    color: #33355a;
  }

  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    color: $vlb-300-font;
  }
}

.about-banner-image {
  position: relative;
  top: -40rem;
  z-index: 2;
  margin-bottom: -15rem;
}

.our-goal {
  padding: 10rem 0;
}

.goal-text {
  width: 92%;
  h3 {
    color: #33355a;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
  }
}

.focus-section {
  padding: 8rem 0rem 6rem;

  .focus-about-intro-text {
    width: 66%;
    margin: 0 auto;

    h2 {
      font-weight: 600;
      font-size: 42px;
      color: #ffffff;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
    }
  }
}

.focus-items-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    margin-right: 1rem;
  }
}

.focus-item {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2rem 1rem 1rem;
  transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;

  h4 {
    color: $vlb-300-font;
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-weight: 200;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.048px;

    color: #031b30;

    // opacity: 0.8;
  }
}

.focus-item:hover {
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  transform: translateY(-3px) !important;
}

@media (max-width: 990px) {
  .about-intro-text {
    width: 100%;
    color: #fff;
  }

  .about-banner-carousel {
    position: relative;
    top: -50rem;
    z-index: 2;
    margin-bottom: -15rem;
  }

  .goal-image-container {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
  }

  .focus-items-container {
    grid-template-columns: repeat(2, 1fr);

    div {
      margin: 1rem;
    }
  }
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .about-intro-text,
  .focus-about-intro-text {
    min-width: 100%;
  }

  .introduction {
    padding: 7rem 0 12rem;
    // height: 60vh;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
      line-height: 28px;
    }
  }

  .about-banner-carousel {
    z-index: 2;
    position: relative;
    top: -46rem;
    margin-bottom: -10rem;
  }

  .banner-carousel-image {
    width: 100%;
  }

  .our-company {
    padding: 5rem 0 4rem;

    h2 {
      font-weight: bold;
      font-size: 36px;
    }

    p {
      line-height: 26px;
      font-size: 16px;
    }
  }

  .our-goal {
    padding: 5rem 0;
    h3 {
      font-size: 36px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }
    img {
      width: 100;
    }
  }

  .goal-text {
    width: 100%;
  }

  .focus-items-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 420px) and (max-width: 576px) {
  .introduction {
    padding: 7rem 0 18rem;
  }
}

@media screen and (max-width: 350px) {
  .introduction {
    padding: 7rem 0 5rem;
  }
}

@media screen and (max-width: 320px) {
  .about-banner-carousel {
    top: -50rem;
  }
}

@media (min-width: 1024px) {
  .our-goal {
    img {
      width: 96%;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .introduction {
    background-image: url("../../images/about-bg.svg");
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-max-device-pixel-ratio: 1.5) {
  .introduction {
    padding: 14rem 0;
    height: 100vh;
  }
}
