@mixin course-design {
  border: 1px solid #d7dce0;
  border-radius: 4px;
}

@mixin course-typo {
  font-weight: 300;
  font-size: 17px;
  line-height: 30px;
}

@mixin program-mobile-padding {
  padding: 2rem 1rem 1.5rem 1rem;
}

.program-banner {
  padding: 5rem 0 5rem;
  color: #ffffff;
}

.institution-filter {
  margin-left: -1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
}

.back-cta {
  font-size: 14px;
  font-weight: 400;
}

.course-intro {
  margin-top: 5rem;
  p {
    font-weight: 200;
    font-size: 18px;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
  }
}

.course-intro-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4rem;
}

.course-intro-details-wrapper {
  position: relative;
  top: -4rem;
  margin-bottom: -4rem;
}

.course-intro-details {
  display: flex;
  @include course-design();
  background-color: #ffffff;
  padding: 2rem 0;

  img {
    width: 42px;
    height: 42px;
  }

  p {
    color: #051a52;
    font-size: 12px;
    font-weight: 200px;
    margin: 0;
  }

  h3 {
    color: #24292e;
    font-size: 24px;
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.course-intro-item {
  margin-left: 1rem;
  h3 {
    font-size: 22px;
  }

  div {
    display: flex;
    align-items: baseline;

    h5 {
      margin-left: 1rem;
      text-decoration: line-through;
      text-decoration-thickness: 2px;
      color: #81909d;
      font-weight: 600;
    }
  }
}

.course-details {
  margin-top: 1.5rem;
  margin-bottom: 8rem;

  h3 {
    font-weight: 600;
    font-size: 24px;
  }
}

.course-overview {
  padding: 2rem 2rem 6rem;
  @include course-design();
  p {
    @include course-typo();
  }
}

.course-outline {
  @include course-design();
  margin-top: 5rem;
}

.course-outline-header {
  padding: 1.5rem 4rem;
}

.course-content {
  padding: 2rem 4rem;
  color: #33355a;
  border-top: 1px solid #d7dce0;
  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-top: 0.5rem;
  }
}

.course-content-inner {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);

  &--open {
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
  }
}

.course-topic {
  font-size: 18px;
  // line-height: 40px;
  font-weight: 500;
  margin-top: 2rem;
}

.expand-cta {
  vertical-align: middle;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}

.topic-content {
  margin: 1rem 0 0 2rem;

  li {
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
  }
}

.child-topic-item {
  // font-size: 12px!important;
  font-weight: 300 !important;
}

.inner-child-content {
  margin-left: 2rem;
}

.tutor-section {
  margin-top: 6rem;
  h4 {
    margin-left: 4rem;
    font-weight: 600;
    font-size: 24px;
  }
}

.tutor-profile {
  @include course-design;
  margin-top: 2rem;

  a,
  a:hover {
    color: #0f42a4;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    word-break: break-all;
  }
}

.tutor-profile-intro {
  padding: 2rem 2rem 1.5rem 4rem;
  border-bottom: 1px solid #d7dce0;

  img {
    width: 100%;
  }

  .intro-details {
    margin-left: 1.5rem;
  }

  #tutor-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }

  #tutor-course {
    font-weight: normal;
    font-size: 17px;
  }

  #tutor-experience {
    color: #33355a;
    font-size: 14px;
  }
}
.tutor-about {
  padding: 2rem 2rem 1.5rem 4rem;

  p {
    @include course-typo();
  }
}

.course-intro-ctas {
  width: 36%;
}
@media (max-width: 1200px) {
  .course-intro-ctas {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .course-intro-header {
    // flex-direction: column;
    display: block;
    div {
      margin-top: 2rem;
    }
  }

  .course-intro-ctas {
    width: 60%;

  }

  .course-intro-details {
    padding: 2rem 1rem;
    h3 {
      font-size: 16px;
    }
  }

  .course-overview {
    padding: 2rem 1rem 3rem;
  }

  .mobile-top {
    margin-top: 2rem;
  }

  .course-content {
    padding: 2rem 1rem;
  }
  .course-outline-header {
    padding: 1rem;
  }

  .tutor-profile-intro {
    padding: 2rem 2rem 1.5rem 2rem;
    border-bottom: 1px solid #d7dce0;
    display: flex;
    justify-content: space-between;

    img {
      width: 100%;
    }

    .intro-details {
      margin-left: 1.5rem;
    }
  }
}

@media (max-width: 576px) {
  .course-intro {
    h2 {
      font-size: 28px;
    }
  }

  .course-overview {
    padding: 2rem 1rem;
    p {
      font-size: 14px;
      line-height: 25px;
    }
  }

  .course-intro-details {
    h3 {
      font-size: 14px;
    }
  }

  .course-intro-item {
    div {
      flex-direction: column;

      h5 {
        margin: 0.5rem 0 0;
        font-size: 14px;
      }
    }
  }

  .course-content {
    h3 {
      font-size: 18px;
    }
  }

  .topic-content {
    margin: 1rem 0 0 0.5rem;
  }

  .tutor-section {
    h4 {
      margin-left: 1rem;
    }
  }

  .institution-filter {
    grid-template-columns: repeat(2, 1fr);
  }

  .tutor-profile-intro {
    @include program-mobile-padding();
    .intro-details {
      flex-direction: column;
      margin-left: 1rem;
    }
  }

  .tutor-about {
    @include program-mobile-padding();
    p {
      line-height: 24px;
      font-size: 15px;
    }
  }
  .course-intro-ctas {
    display: block!important;
  }

  .course-download-cta {
    margin-bottom: 2rem
  }
}
