.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.stats-container {
    display: flex;
    transition: ease 1000ms;

    .overlay {
        width: 100%;
        height: 474px;
        text-align: center;
        padding-top: 100px;
        color: white;
        background: #0b2f7a;
        background: linear-gradient(180deg, rgba(11, 47, 122, 0) 0%, #0b2f7a 100%);
        background-size: cover;
        background-repeat: no-repeat;
        #text {
            position: absolute;
            top: 85%;
            left: 50%;
            font-size: 26px;
            font-weight: bold;
            color: white;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
        }
    }
    .stats-card {
        height: 50%;
        padding-top: 2rem;
        padding-left: 2rem;
        text-transform: capitalize;

        h3 {
            margin-bottom: 1rem;
            font-size: 22px;
            font-weight: bold;
        }

        .image-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            img {
                margin: 0 2rem 2rem 0;
                width: 70px;
            }
        }
        @media (max-width: 768px) {
            .image-box {
                img {
                    margin: 0 0 0.5rem 0;
                    transform: scale(0.5);
                }
            }
        }
    }
}

.box-container {
    display: flex;
}

.stat-box {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 10px 1px 12px rgba(142, 128, 128, 0.23);
}

.box-3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid rgb(215, 224, 233);
    text-transform: capitalize;
    align-items: center;
    height: 100%;

    h3 {
        margin-bottom: 1rem;
        font-size: 22px;
        font-weight: bold;
    }
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #e0e0e0;
}
