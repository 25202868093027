.program-card {
  width: 100%;
  background: #ffffff;
  min-height: 25rem;
  box-shadow: 0px 4px 12px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  padding: 1rem;

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #041644;
  }

  p {
    font-weight: 200;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #051a52;
  }
}
.close {
  color: #ffffff !important;
}
.modal-content {
  border: none;

  .payment-modal-heading {
    background: #0b2f7a;
    border-radius: 4px;
    height: 80px;

    .title {
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
      display: flex;
      margin-top: 20px;
    }
    button.close {
      margin-top: -28px;

      .close {
        color: #ffffff !important;
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 30% 70%;
}

.payment-modal {
  width: 100%;
  background: #ffffff;
  min-height: 15rem;
  box-shadow: 0px 4px 12px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  padding: 1.5rem;
  margin: 1.5rem auto;

  .modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #33355a;
  }

  .content-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #33355a;
  }

  .content-body {
    font-size: 16px;
    line-height: 30px;
    color: #33355a;
  }

  .notice {
    font-style: italic;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #0b2f7a;
  }
}

.payment-card {
  width: 100%;
  background: #ffffff;
  min-height: 15rem;
  box-shadow: 0px 4px 12px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  padding: 1.5rem;

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #041644;
  }

  p {
    font-weight: 200;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #051a52;
  }
}

.program-status {
  padding: 4px 8px;
  border-radius: 4px;

  &--active {
    background: #eafbf1;
    color: #179e4d;
  }

  &--closed {
    background: #fce9e9;
    color: #e43535;
  }
}

.degree-type-tag {
  border: 1px solid #81909d;
  border-radius: 15px;
  padding: 0.2rem 1rem;
}

.course-card-cta {
  position: absolute;
  bottom: 2.5rem;
  width: 87%;
}

.modal-body {
  padding: 20px;
}
.embed-responsive::before {
  display: none !important;
}
