$font-family: inter;


// Color Variables


$vlo-100-color: #EAA53A;  //vigilearn orange
$vlo-200-color: #F7BD88;
$vlo-300-color: #F26527;
$vlo-400-color: #0F42A4;


$vlg-100-color: #51B74B;  //vigilearn green

  //vigilearn teal



$vlb-000-color: #FFFFFF;
$vlb-100-color: #0B2F7A;
$vlb-200-color: #1354D3;
$vlb-300-color: #3E63F6;
$vlb-400-color: #345DEE;
$vlb-500-color: #E8EFFD;



$vlb-100-font: #133881;
$vlw-200-font: #BAC2C9;
$vlb-300-font: #3A434B;
$vlb-400-font: #07365F;
$vlb-500-font: #33355A;


//CLEAR AS THEY ARE NO LONGER IN USE
$vlb-800-color: #345DEE;
$vln-000-color: #345DEE;
$vlb-600-color: #345DEE;
$vlb-900-color: #345DEE;
$vlg-600-color: #345DEE;
$vlb-700-color: #345DEE;
$vln-050-color: #345DEE;
$vln-050-color: #345DEE;


