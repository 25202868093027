@import "../variables";

//BANNER
.partner-intro {
  color: #ffffff;
}

.partner-hero-image {
  height: 100vh;
  background-image: url("../../images/interracial-partnership.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

@mixin hult-partner-bg {
  background-image: url("../../images/hultBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hult-bg {
  position: relative;
  z-index: 2;
  padding: 25rem 0 0 6rem;
  @include hult-partner-bg;
}

.hult-bg-text {
  float: right;
  background-image: url("../../images/hult-scholarship-bg.png");
  padding: 2.5rem 3.3rem;
  opacity: 0.8;
  width: 650px;
  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
  }

  p {
    text-align: end;
    margin-top: 1rem;
    letter-spacing: 0.25px;
    font-size: 1.5rem;
    line-height: 34px;
    color: #ffffff;
  }
}

.hult-bg-image {
  height: 272px;
  width: 100%;
  @include hult-partner-bg;
}

.mobile-hult-details {
  padding: 6rem 2rem;
  color: #ffffff;

  h1 {
    font-size: 32px;
    letter-spacing: 0.25px;
    width: 80%;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 2rem;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

.partner-hero-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8rem;
  h1 {
    font-size: 54px;
    font-weight: 600;
    line-height: 70px;
  }

  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 3.5rem;
  }
}

.partner-form-container {
  padding: 6rem 0;
  h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
  }

  p {
    padding-right: 6rem;
    font-size: 18px;
    line-height: 32px;
  }
}

.partner-form {
  background: #ffffff;
  border: 0.5px solid #bac2c9;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  padding: 4rem 2rem 5rem;

  .input-fleid {
    height: 3.5rem;
  }
}

.institutions-container {
  padding: 6rem 0 8rem;
}

.hult-apply {
  display: block;
  width: 100%;
  height: 100%;
}

.tab-button {
  color: #505d67;
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}
.tab-button.isActive {
  color: #1354d3;
  border-bottom: 5px solid #1354d3;
}

// .stats-container {
//   background: #ffffff;
//   border-radius: 15px;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
//   display: flex;

//   .overlay {
//     width: 100%;
//     height: 474px;
//     text-align: center;
//     padding-top: 100px;
//     color: white;
//     overflow: hidden;
//     background: #0b2f7a;
//     background: linear-gradient(180deg, rgba(11, 47, 122, 0) 0%, #0b2f7a 100%);
//     background-size: cover;
//     background-repeat: no-repeat;
//     #text {
//       position: absolute;
//       top: 85%;
//       left: 50%;
//       font-size: 26px;
//       font-weight: bold;
//       color: white;
//       transform: translate(-50%, -50%);
//       -ms-transform: translate(-50%, -50%);
//     }
//   }
//   .stats-card {
//     height: 50%;
//     padding-top: 2rem;
//     padding-left: 2rem;
//     text-transform: capitalize;

//     h3 {
//       margin-bottom: 1rem;
//       font-size: 22px;
//       font-weight: bold;
//     }

//     .image-box {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: space-between;
//       img {
//         margin: 0 2rem 2rem 0;
//         width: 70px;
//       }
//     }
//     @media (max-width: 768px) {
//       .image-box {
//         img {
//           margin: 0 0 0.5rem 0;
//           transform: scale(0.5);
//         }
//       }
//     }
//   }
// }

.hult-apply-steps {
  background: linear-gradient(180deg, #0b2f7a 0%, #051a52 100%);
  // transform: rotate(-90deg);
  color: #ffffff;
  justify-content: center;
  padding-top: 90px;
  padding-left: 70px;
  @media (max-width: 768px) {
    padding: 40px;
  }

  h3 {
    font-weight: 700;
    font-size: 34px;
    justify-content: start;
  }
}

.institutions-containter-text {
  width: 65%;

  h3 {
    font-size: 40px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
  }
}

.institution-partner-logos {
  margin-top: 4rem;
}

.box {
  position: relative;
  margin-bottom: 2.3rem;
  max-height: 228px;
  height: 228px;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  &--bordered {
    border: 1px solid #bac2c9;
  }

  .content-hover {
    transform: scale(0, 0);
    position: absolute;
    height: 100%;
    width: 100%;
    background: url("../../images/university-img.png");
    transition: all 0.2s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;

    div {
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
      height: 100%;
    }

    h4 {
      color: #ffffff;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 2rem;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-flow: column;

    img {
      width: 100px;
    }
  }

  &:hover > {
    .content-hover,
    .content-hover2 {
      transform: scale(1, 1);
      cursor: pointer;
    }
  }
}

.multiple-instituion-info-container {
  margin-bottom: 3rem;
  width: 100%;
  border: 1px solid #d7dce0;
  border-radius: 15px;
}

.multiple-alumni-images {
  width: 100%;
  height: auto;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  div {
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 2rem;
    min-width: 200px;

    @media (max-width: 768px) {
      min-width: none;
      position: static;
      background-color: transparent;
      padding: 0;
    }
  }
}

.multiple-institution-info-img {
  width: 100%;
  height: auto;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.multiple-institution-partner-logo {
  position: absolute;
  bottom: 0px;
  left: 5rem;
  width: 220px;
  height: 70px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.multiple-institution-info-text {
  margin: 2rem 5rem;

  @media (max-width: 768px) {
    margin: 1rem;
  }
}
.multiple-learn-more {
  margin: 0 5rem 1rem;

  button {
    border: 1px solid #0b2f7a;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 500;
    background-color: transparent;
    color: #0b2f7a;
    margin-right: 1rem;
  }
}

.multiple-institution-payment-banner {
  padding: 5rem 0;
  color: #33355a;
  // background-color: #0B2F7A;
  background-color: #f7fafc;

  h3 {
    font-weight: 700;
    font-size: 34px;
  }

  p {
    font-weight: 500;
    font-size: 20px;
  }
}

.multiple-institution-partner-banner {
  margin-top: 4rem;
  padding: 10rem 0 5rem;
  color: #ffffff;
  background-color: #0b2f7a;
  text-align: center;
  // background-image: url("../../images/partner-hero-bg.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  h2 {
    font-weight: 700;
    font-size: 40px;
  }

  h3 {
    font-weight: 700;
    font-size: 34px;
  }

  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }
}

.about-university {
  padding-top: 8rem;
  padding-bottom: 8rem;

  h3 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  p {
    font-size: 18px;
    line-height: 30px;
  }
}

.univeristy-image {
  //   height: 100%;
  img {
    border-radius: 15px;
    height: 25rem;
  }
}

.rit-wrapper {
  position: relative;
}

.rit-banner {
  padding: 6rem;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
  }

  p {
    font-size: 24px;
    line-height: 40px;
    color: #3a434b;
    margin-top: 1rem;
  }

  .rit-banner-text {
    // width: 90%;
    margin: 0 auto 6rem;
  }

  .rit-image-box {
    z-index: 5;
    position: relative;
    margin: 0 auto;
    // width: 85%;
    height: 522px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../images/rit-why-us-image.png");
  }

  .rit-image-box2 {
    z-index: 5;
    position: relative;
    margin: 0 auto;
    // width: 85%;
    height: 522px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../images/rit2-why-us-image.png");
  }
}

.multiple-other-schools {
  padding-top: 5rem;
  padding-bottom: 5rem;

  h3 {
    margin-bottom: 5rem;
    color: $vlb-500-font;
    font-size: 40px;
    font-weight: 700;
  }

  .image-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      margin: 0 2rem 2rem 0;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 30px;
    }

    .image-box {
      img {
        margin: 0 0 0.5rem 0;
        transform: scale(0.5);
      }
    }
  }
}

.entry-card {
  margin-bottom: 2rem;
  max-width: 700px;

  .entry-card-content {
    box-shadow: 0 11px 11px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border-top: 10px solid #f26527;
    padding: 2rem;

    h3 {
      margin-bottom: 2rem;
      margin-top: 2rem;
      color: $vlb-500-font;
      font-size: 34px;
      font-weight: 700;
    }

    .entry-card-title {
      font-size: 1rem;
      font-weight: 700;
      margin-top: 1rem;
    }

    ol {
      padding-inline-start: 20px !important;
    }

    .dashed {
      list-style-type: "–   ";
    }

    @media (max-width: 768px) {
      height: auto;
      padding: 1rem;
    }
  }
}

.why-study-with-us-container {
  padding: 4rem;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
}

.spacing {
  top: -25rem;
  padding-top: 25rem;
  margin-bottom: -25rem;
  position: relative;
}

.why-study-with-us {
  display: flex;
  flex-wrap: wrap;

  .study-card {
    margin-bottom: 2rem;
  }

  .study-card-content {
    box-shadow: 0 11px 11px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    height: 285px;
    // height: 255px;
    background-color: white;
    border-radius: 15px;
    padding: 2rem;

    .study-card-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
      margin-left: 8px;
      text-transform: capitalize;
    }

    @media (max-width: 768px) {
      height: auto;
      padding: 0.5rem;
    }
  }
}

.course-individual {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  // width: 100%;
  height: auto;
  background-color: white;
  padding: 2rem;
  padding-left: 6rem;
  padding-right: 4rem;
  margin-left: -2rem;
  margin-right: -2rem;

  &:hover {
    color: #1354d3;
  }

  .flex {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.program-individual-card {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: auto;
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;

  .flex {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .color-primary {
    color: #1354d3;
  }

  .sub-title {
    margin: 2rem 0 1rem;
    font-weight: 600;
  }

  .program-fees-box {
    display: flex;
    align-items: center;
    font-size: 11px;

    @media (max-width: 768px) {
      span {
        line-height: 2rem;
      }
      margin-top: 1rem;
      flex-direction: column;
      align-items: flex-start;
    }

    .right {
      margin-right: 20px;
    }
  }
}
.other-schools {
  padding-top: 10rem;
  padding-bottom: 10rem;

  h2 {
    margin-bottom: 5rem;
    color: $vlb-500-font;
    font-size: 40px;
    font-weight: 700;
  }
}

.institution-programs {
  padding: 8rem 0 10rem;
}

@media (max-width: 768px) {
  .partner-hero-text {
    padding: 0 3rem;
  }
  .partner-form-container {
    padding: 6rem 0;
    h1 {
      font-size: 48px;
      line-height: 54px;
    }

    p {
      padding-right: 0rem;
    }

    .partner-form {
      padding: 6rem 1rem 5rem;
    }
  }

  .about-university {
    h3 {
      margin-top: 3rem;
    }
  }
}

@media (max-width: 576px) {
  .partner-intro {
    padding-top: 7rem;
    padding-bottom: 3rem;
  }

  .partner-hero-text {
    padding: 0 1rem;
    h1 {
      font-size: 32px;
      line-height: 36px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      margin: 1rem 0 4rem;
    }
  }
  .partner-form-container {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .partner-form {
    padding: 4rem 1rem !important;
    margin-top: 4rem;
  }

  .institutions-containter-text {
    width: 100%;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 18px;
    }
  }

  .multiple-institution-partner-banner {
    padding: 5rem 0;
    h2 {
      font-size: 30px;
    }

    p {
      margin-top: 1rem;
      font-size: 18px;
    }
  }

  .about-university {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .other-schools {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2 {
      margin-bottom: 3rem;
      font-size: 32px;
    }
  }

  .univeristy-image {
    // height: 100%;
    img {
      border-radius: 15px;
      height: 15rem;
    }
  }
}

@media (min-width: 576px) and (max-width: 990px) {
  .partner-intro {
    padding: 10rem 0 3rem 0;
  }
}

@media screen and (min-width: 990px) and (max-width: 1200px) {
  .partner-intro {
    padding: 12rem 0 8rem 0;
  }
  .partner-hero-text {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 990px) and (max-width: 1280px), screen and (min-height: 768px) {
  .partner-hero-image {
    // height: 80vh;
    height: 700px;
  }
}

@media (min-width: 1400px) {
  .partner-intro-container {
    max-width: 1500px;
  }
}

.course-individual .modal-video {
  background-color: rgba(0, 0, 0, 0.08);
}