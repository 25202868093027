@import "../variables";

.faq-root {
  margin-top: 4rem;
  padding: 12rem 0;
  color: #435a70;
  h3 {
    font-size: 38px;
    font-weight: bold;
  }

  .question-section {
    margin: 5rem auto;
    width: 80%;
  }
}

.faq-container {
  background-image: url("../../images/faq-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.faq-bar {
  background: #ffffff;
  padding: 2rem 1.5rem 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 10px 20px rgba(213, 215, 220, 0.826);
  border-radius: 5px;
  h4 {
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    font-style: italic;
  }
}

.answer-section {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);

  &--open {
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
  }
}

@media (max-width: 576px) {
  .faq-root {
    padding: 6rem 0;
    h3 {
      font-size: 24px;
      line-height: 32px;
    }

    .question-section {
      margin: 3rem auto;
      width: 100%;
    }
  }

  .faq-bar {
    margin-bottom: 0.8rem;
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .faq-container {
    background-image: unset;
  }
}
