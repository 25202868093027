@import "../variables";

.blogs-container {
  margin-top: 75px;

  h2,
  h3 {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
  }

  .blogs-header {
    padding: 7rem 0 5rem;
    margin: 0 auto;

    &--single {
      padding: 7rem 0 15rem;
    }
  }
}

.blog-content-wrapper {
  padding: 7rem 0 10rem;
}

.blog-card {
  min-height: 34rem;
  box-shadow: 0px 4px 12px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  border: 0.5px solid #bac2c9;
  margin-top: 2rem;
  img {
    height: 182px;
    border-radius: 15px;
  }
}

.blog-card-content {
  padding: 1rem 1rem 2rem;

  h4 {
    color: #33355a;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
  p {
    margin-top: 1rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #3a434b;
  }
}

.other-posts {
  margin-top: 5rem;
  h3 {
    margin-bottom: 3rem;
    font-weight: 600;
    font-size: 34px;
  }
}

.single-blog-content {
  width: 65%;
  margin: 0 auto;

  img {
    border-radius: 15px;
    width: 100%;
    height: 357px;
    margin-bottom: 5rem;
  }
}

.blog-subtitle {
  font-size: 26px;
}

.single-blog-body {
  margin-top: -6rem;
  color: $vlb-500-font;

  p,
  ul {
    font-size: 16px;
    line-height: 28px;
  }
}

@media (max-width: 992px) {
  .single-blog-content {
    width: 85%;

    img {
      border-radius: 15px;
      width: 100%;
      height: 400px;
    }
  }
}

@media (max-width: 600px) {
  .blogs-header {
    padding: 4rem 0 8rem !important;
  }

  .all-blogs-header {
    padding: 3rem 0 4rem !important;
  }

  .blog-content-wrapper {
    padding: 3rem 0 5rem;
  }

  .blog-card {
    margin-top: 3rem;
  }

  .blogs-container {
    margin-top: 75px;

    h2 {
      font-size: 36px;
      line-height: 36px;
    }
    h3 {
      font-size: 26px;
      line-height: 36px;
    }
  }
  .single-blog-body {
    margin-top: -4rem;
  }

  .single-blog-content {
    width: 95%;

    img {
      height: 200px;
    }
  }
  .other-posts {
    h3 {
      margin-bottom: -2rem;
    }
  }
}
