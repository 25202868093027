// @import url("https://fonts.googleapis.com/css?family=Inter:wght@100,regular,bold,italic&subset=latin,latin-ext");
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import "variables";

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 14px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
b,
i,
li {
  font-family: "Inter";
}

a {
  text-decoration: none;
  // color: #FFFFFF!important;
  // @include transition();
}
a:hover {
  // text-decoration: none !important;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.icon {
  color: $vlb-800-color;
}


//BACKGROUNDS
.bg-blue-primary {
  background-color: $vlb-100-color;
}

.bg-blue-secondary {
  background-color: $vlb-500-color;
}

.bg-blue-tetiary {
  background-color: $vlb-200-color;
}

.bg-blue-light {
  background-color: $vlb-500-color;
}

.bg-white {
  background-color: $vlb-000-color;
}

//SPACES
.space-top-1 {
  margin-top: 2rem;
}

.space-top-2 {
  margin-top: 4rem;
}

.space-top-3 {
  margin-top: 6rem;
}

.space-top-4 {
  margin-top: 8rem;
}


.space-bottom-1 {
  margin-bottom: 2rem;
}

.space-bottom-2 {
  margin-bottom: 4rem;
}

.space-bottom-3 {
  margin-bottom: 6rem;
}

.space-bottom-4 {
  margin-bottom: 8rem;
}

.vigilearn-logo {
  width: 5.5rem;
}

.light-blue-text {
  color: $vlb-600-color;
}

.product-header {
  color: $vlb-600-color;
  font-size: 40px;
  font-weight: bold;
}

.card-hover {
  transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;
}
.card-hover:hover {
  box-shadow: 0 11px 11px 0 rgba(0, 0, 0, 0.16) !important;
  transform: translateY(-5px) !important;
}


.orange-text {
  color: $vlo-300-color;
}

.white-text {
  color: $vlb-000-color !important;
}

.green-text {
  color: $vlg-100-color;
}

.dark-text {
  color: $vlb-300-font;
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 9px !important;
  height: 9px !important;
  border: 1px solid #bac2c9 !important;
}

.react-multi-carousel-dot--active button {
  background: $vlo-300-color !important;
}

.intro-text {
  margin: 0 auto;
  text-align: center;
  width: 52%;
  padding: 10rem 0 5rem;

  h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    // color: $vlb-300-color;
  }

  p {
    margin-top: 1rem;
    // color: $vlb-800-color;
    font-size: 16px;
   line-height: 30px;
  }
}

.download-cta {
  margin-top: 4rem;
}

.product-video {
  height: 35rem;
  width: 100%;
}

.iframe {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.features-header {
  width: 43%;
  margin: 0 auto 4rem;
  h4 {
      color: $vlw-200-font;
      font-weight:bold;
      font-size: 18px;
  }

  h3 {
      font-weight:bold;
      font-size: 24px;
      line-height: 32px;
  }
}


// Cookie

@keyframes cookie-display {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
        
  .cookie-container {
    padding: .5rem 6rem;
      color: black!important;
      background-color: #FFFFFF!important;
      display: flex;
      align-items: center!important;
      box-shadow: 0 7px 30px 5px rgba(150, 170, 180, 0.5)!important;
      -webkit-animation: cookie-display 1s ease-out;
      -moz-animation: cookie-display 1s ease-out;
      -o-animation: cookie-display 1s ease-out;
      animation: cookie-display 1s ease-out;
    }

    .cookie-btn {
      transition: all .7s cubic-bezier(.2,1,.22,1);
      &:hover {
        background-color: #27BE63;
        cursor: pointer;
        box-shadow: 0px 10px 20px rgba(17, 122, 243, 0.3);
        transform: translateY(-3px);
      }
  }

@media (max-width: 1024px) {
  .cookie-container {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .card-hover {
    // margin: 0 1rem;
  }

  .intro-text {
    width: 100%;
  }

  .product-video {
    height: 15rem;
  }
  .header-logo {
    width: 4rem;
  }
}

@media (max-width: 768px) {
  .intro-text {
    h2 {
      font-size: 28px;
      line-height: 30px;
    }
  }
}

// Mobile devices
@media (max-width: 480px) {
  .cookie-container {
  }
}

//Extra small devices (Iphone 5s)
@media (max-width: 320px) {
}
