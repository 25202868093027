.rating-root {
    margin-top: 3rem;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 576px) {
        img {
            transform: scale(.5);
        }
      }
}

.hide-sm {
    @media (max-width: 576px) {
        display: none;
    }
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        margin-right: .5rem;
    }

    h3 {
        margin-top: 1rem;
    }

    
}
.background {
    background-color: #F2F5F8;
    p {
        color: red;
    }
    h4 {
        font-weight: 600;
    }
}