.location-root {
    padding-top: 5rem;
    // padding-bottom: 5rem;

    .card {
        box-shadow: 0px 1px 15px rgba(188, 188, 188, 0.25);
        margin: 0.5rem;
        padding: 0.5rem;
        max-width: 200px;
    }
  
    p {
      font-size: 18px;
      line-height: 30px;
      padding-left: 0px;
      padding-right: 0px;
      float: right;
    }
  }

.hide-sm {
    @media (max-width: 576px) {
        display: none;
    }
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        margin-right: .5rem;
    }

    h6 {
        margin-top: 1rem;
        font-weight: 500;
    }

    
}
.background {
    background-color: #F2F5F8;
    p {
        color: red;
    }
    h4 {
        font-weight: 600;
    }
}