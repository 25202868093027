.alumni-network {
    p {
        font-size: smaller;
    }

    h6 {
        font-size: 30px;
        font-weight: 600;
        
    }

    h6.right {
        border-right: 1px solid;
        @media (max-width: 768px) {
            border-right: none;
          }
    }
    text-align: center;
    // display: flex;
    margin: 3rem 0 0;
}