@import "../variables";

.products-container {
  margin-top: 75px;
  font-size: 16px;
  .product-container {
    padding: 8rem 0;
    color: $vlb-300-font;
    h1 {
      font-weight: bold;
      line-height: 56px;
      font-size: 44px;
    }
    p {
      line-height: 28px;
      margin-top: 2rem;
    }
  }
}

.product-image {
  width: 100%;
}

.check-icon {
  border-radius: 50%;
  padding: 0 0.1rem;
  background-color: #51b74b;
  color: #ffffff;
}

.products-brochure {
  color: #ffffff;
  padding: 7.5rem 0;
  mix-blend-mode: multiply;
  background-image: url("../../images/brochure-bg.png");
  background-size: cover;
  h1 {
    padding-right: 4rem;
    line-height: 48px;
    font-weight: 700
  }
}

.product-banner {
  padding-top: 6rem;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    color: $vlb-300-font;
    margin-top: 1rem;
  }

  .product-wrapper {
    position: relative;
  }

  .product-banner-text {
    width: 65%;
    margin: 0 auto 6rem;
  }

  .product-image-box {
    z-index: 5;
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
    width: 85%;
    height: 522px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(2, 24, 73, 0.4);
    background-image: url("../../images/product-video-placeholder.png");
  }
}

.product-play-container {
  cursor: pointer;
  background: rgba(202, 220, 255, 0.44);
  border-radius: 50%;
  padding: 2rem;
}

.product-key-features {
  width: 100%;
  position: relative;
  background-image: url("../../images/product-featuers-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  top: -16rem;
  margin-bottom: -16rem;
  padding: 23rem 0 15rem;

  h2 {
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 5rem;
  }

  &--light-bg {
    background-image: url("../../images/product-features-light.png");
  }
}

.features-content {
  width: 75%;
}

.features-content--light {
  width: 45%;
}

.feature-card {
  border-radius: 15px;
  background-color: #ffffff;
  padding: 2rem 1.5rem 1rem;
  margin-bottom: 2rem;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $vlb-400-font;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: $vlb-500-font;
  }
}

.banner-text-light,
.banner-text-light p {
  color: #ffffff;
}

#lms-subtext {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .products-brochure {
    background-color: $vlb-100-color;
    background-image: none;
  }
  .product-banner {
    .product-banner-text {
      width: 80%;
    }

    .product-image-box {
      width: 90%;
      height: 422px;
    }
  }

  .features-content {
    width: 90%;
    word-break: break-word;
  }
  .features-content--light {
    width: 80%;
  }
}

.download-cta {
  border: 1px solid #ffffff;
  padding: .9rem 1.5rem;
  border-radius: 5px;
  font-weight: 500;
  background-color: transparent;
  color: #ffffff;
  margin-top: 2rem;
}

@media (max-width: 990px) {
  .product-image {
    width: 80%;
  }

  .product-banner {
    h1 {
      font-size: 40px;
    }

    .product-banner-text {
      width: 100%;
    }
    .product-image-box {
      height: 322px;
    }
  }

  .product-key-features {
    padding: 17rem 0;
    top: -10rem;
    margin-bottom: -10rem;
  }
}

@media (max-width: 576px) {
  .products-container {
    font-size: 14px;
    .product-container {
      padding: 4rem 0;
      h1 {
        margin-top: 2rem;
        line-height: 38px;
        font-size: 28px;
      }
      p {
        line-height: 24px;
      }
    }
  }

  .product-image {
    width: 90%;
  }

  .products-brochure {
    padding: 4rem 0;
    h1 {
      font-size: 24px;
      padding-right: 0;
      line-height: 32px;
    }
  }

  .product-banner {
    padding-top: 3rem;
    h1 {
      font-weight: bold;
      font-size: 28px;
      line-height: 38px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }

    .product-banner-text {
      width: 100%;
      margin: 0 auto 3rem;
    }
    .product-image-box {
      width: 90%;
      height: 180px;
    }
  }

  .product-play-container {
    padding: 1rem;
    img {
      width: 15px;
      height: 20px;
    }
  }

  .product-key-features {
    padding: 10rem 0;
    top: -5rem;
    margin-bottom: -5rem;

    h2 {
      font-size: 28px;
      margin-bottom: 2rem;
    }

    .feature-card {
      h3 {
        font-size: 20px;
      }
    }

    .features-content {
      width: 85%;
    }
  }
  #lms-subtext  {
    width: 100%;
  }
}
