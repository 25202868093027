@import "../variables";

//BANNER
.partner-intro {
  color: #ffffff;
}

.partner-hero-image {
  height: 100vh;
  background-image: url("../../images/interracial-partnership.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

.partner-hero-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8rem;
  h1 {
    font-size: 54px;
    font-weight: 600;
    line-height: 70px;
  }

  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 3.5rem;
  }
}

.partner-form-container {
  padding: 6rem 0;
  h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
  }

  p {
    padding-right: 6rem;
    font-size: 18px;
    line-height: 32px;
  }
}

.partner-form {
  background: #ffffff;
  border: 0.5px solid #bac2c9;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(188, 188, 188, 0.25);
  border-radius: 15px;
  padding: 4rem 2rem 5rem;

  .input-fleid {
    height: 3.5rem;
  }
}

.institutions-container {
  padding: 6rem 0 8rem;
}

.institutions-containter-text {
  width: 65%;

  h3 {
    font-size: 40px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
  }
}

.institution-partner-logos {
  margin-top: 4rem;
}

.box {
  position: relative;
  margin-bottom: 2.3rem;
  max-height: 228px;
  height: 228px;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  &--bordered {
    border: 1px solid #bac2c9;
  }

  .content-hover {
    transform: scale(0, 0);
    position: absolute;
    height: 100%;
    width: 100%;
    background: url("../../images/university-img.png");
    transition: all 0.2s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;

    div {
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
      height: 100%;
    }

    h4 {
      color: #ffffff;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 2rem;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-flow: column;

    img {
      width: 100px;
    }
  }

  &:hover > {
    .content-hover,
    .content-hover2 {
      transform: scale(1, 1);
      cursor: pointer;
    }
  }
}

.institution-partner-banner {
  margin-top: 4rem;
  padding: 10rem 0 5rem;
  color: #FFFFFF;
  background-image: url("../../images/partner-hero-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-weight: 700;
    font-size: 54px;
  }

  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }
}

.about-university {
  padding-top: 8rem;
  padding-bottom: 8rem;
  
  h3 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  p {
    font-size: 18px;
    line-height: 30px;
  }
}

.univeristy-image {
//   height: 100%;
  img {
    border-radius: 15px;
    height: 25rem;
  }
}

.other-schools {
  padding-top: 10rem;
  padding-bottom: 10rem;

  h2 {
    margin-bottom: 5rem;
    color: $vlb-500-font;
    font-size: 40px;
    font-weight: 700;
  }
}

.institution-programs {
  padding: 8rem 0 10rem;

}

@media (max-width: 768px) {
  .partner-hero-text {
    padding: 0 3rem;
  }
  .partner-form-container {
    padding: 6rem 0;
    h1 {
      font-size: 48px;
      line-height: 54px;
    }

    p {
      padding-right: 0rem;
    }

    .partner-form {
      padding: 6rem 1rem 5rem;
    }
  }

  .about-university {
    h3 {
      margin-top: 3rem;
    }
  }
}

@media (max-width: 576px) {
  .partner-intro {
    padding-top: 7rem;
    padding-bottom: 3rem;
  }

  .partner-hero-text {
    padding: 0 1rem;
    h1 {
      font-size: 32px;
      line-height: 36px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      margin: 1rem 0 4rem;
    }
  }
  .partner-form-container {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .partner-form {
    padding: 4rem 1rem !important;
    margin-top: 4rem;
  }

  .institutions-containter-text {
    width: 100%;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 18px;
    }
  }

  .institution-partner-banner {
    padding: 5rem 0;
    h2 {
      font-size: 30px;
    }

    p {
      margin-top: 1rem;
      font-size: 18px;
    }
  }

  .about-university {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .other-schools {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2 {
      margin-bottom: 3rem;
      font-size: 32px;
    }
  }

  .univeristy-image {
    // height: 100%;
    img {
      border-radius: 15px;
      height: 15rem;
    }
}
}

@media (min-width: 576px) and (max-width: 990px) {
  .partner-intro {
    padding: 10rem 0 3rem 0;
  }
}

@media screen and (min-width: 990px) and (max-width: 1200px) {
  .partner-intro {
    padding: 12rem 0 8rem 0;
  }
  .partner-hero-text {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 990px) and (max-width: 1280px),
  screen and (min-height: 768px) {
  .partner-hero-image {
    // height: 80vh;
    height: 700px
  }
}

@media (min-width: 1400px) {
  .partner-intro-container {
    max-width: 1500px;
  }
}
