@import "../variables";

.programs-banner {
  background-image: url("../../images/programs-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#nav-links {
  border-bottom: 6px solid #828282;
  font-weight: 500;
  font-size: 18px;
  margin: 4rem auto 0;
  text-align: center;
  color: #505d67;
  width: 50%;

  li {
    padding: 0.8rem;
    margin-bottom: -0.45rem;

    &:hover {
      cursor: pointer;
      color: $vlb-400-color;
    }
  }
}

.active {
  border-bottom: 6px solid $vlb-400-color;
  color: $vlb-400-color;
}

.programs-header {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}

.course-sort-actions {
  display: flex;
}

.all-courses {
  font-weight: 600;
  font-size: 18px;
  color: #0d0f11;
}

.search-input-field {
  height: 40px;
  width: 350px;
  padding-right: 40px;
}

.courses-count {
  font-weight: 600;
  font-size: 14px;
  color: #9da9b3;
}

.search-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.filter-trigger {
  background-color: #FFFFFF;
  height: 40px;
  border: 1px solid #d7dce0;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
  cursor: pointer;
  margin-left: 2rem;
  width: 150px;
}

.filter-label {
  margin-left: 0.5rem;
}

.filter-parameters {
  font-size: 14px;

  span {
    font-weight: 600;
  }
}

.jot-form-modal {
  width: 4rem;
  position: relative; 
  right: 8%;
   margin: 1rem 0
}

@media (max-width: 768px) {
  .programs-header,
  .course-sort-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-trigger {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  #nav-links {
    width: 100%;
    font-size: 12px;
  }

  .search-input-field {
    width: 300px;
  }
  .jot-form-modal {
    right: 0;
  }
}

@media (max-width: 400px) {
  .program-filter-check {
    margin-left: 1rem;
  }

  .filter-label {
    margin-left: 0.2rem;
  }
}

@media (max-width: 320px) {
  #nav-links {
    font-size: 10px;
  }
}
