.opening-root {
    padding-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // padding-bottom: 5rem;

    .card {
        padding: 1rem 2rem;
        background: rgba(51, 53, 90, 0.05);
        border-radius: 15px;
    }

    p {
        line-height: 35px;
        font-weight: 600;
        font-size: 21px;
    }

    h6.title {
        font-weight: 600;
        font-size: 21px;
        margin-bottom: 2rem;
    }
}

.hide-sm {
    @media (max-width: 576px) {
        display: none;
    }
}
.start {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: start;

    img {
        margin-right: 0.5rem;
        height: 70px;
    }

    h6 {
        margin-top: 1rem;
        font-weight: 500;
    }
}
.background {
    background-color: #f2f5f8;
    p {
        color: red;
    }
    h4 {
        font-weight: 600;
    }
}
