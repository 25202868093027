@import "../variables";

.parent {
  height: 48rem;
  padding-top: 10rem;
  position: relative;

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
  }
}

.form-parent {
  margin-bottom: -32rem;

  h2 {
    // color: $vlb-400-color;
    font-size: 36px;
    font-weight: bold;
  }
}

.form-container {
  position: relative;
  top: -34rem;
  background-color: #FFFFFF;
  margin: 3rem 0 0;
  box-shadow: 0px 4px 10px rgba(143, 148, 155, 0.25);
  border-radius: 16px;
  padding: 8rem 5rem;

  .contact-text {
    margin-top: 2rem;
    font-size: 16px;
    padding-right: 15%;
    // color: $vlb-300-color;
  }

  .input-field {
    height: 50px;
  }

  .form-error,
  .form-error:focus {
    border: 1px solid #dc3545;
    box-shadow: none;
  }

  .submit {
    margin-top: 4rem;
  }

  label {
    // color: $vlb-400-color;
    font-size: 12px;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .form-container {
    padding: 8rem 2rem;
  }
}
