@import "../variables";

@mixin footer-item {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.5px;
}

.footer {
  position: absolute;
  // bottom: 0;
  z-index: 2;
  width: 100%;
  background-color: $vlb-100-color;
  color: #ffffff;

  &--one {
    padding: 80px 0 100px 0;
  }

  &--two {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-top: 1px solid #ffffff;
  }

  .footer-item {
    @include footer-item();
  }

  .footer-link {
    @include footer-item();

    &--active,
    &:hover {
      color: $vlo-200-color;
    }
  }

  .nav a {
    color: #FFFFFF;
    text-decoration: none;
  }

  .footer-policy {
    display: flex;
    gap: 6rem;
  }

  ul {
    // gap: 1rem;

    li {
      margin-top: 1rem;
    }
  }

  h6 {
    font-size: 16px;
    letter-spacing: 0.042em;
    line-height: 12px;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  .icons {
    display: flex;
    // gap: 1.2rem;

    a {
      margin-right: 1.2rem;
    }
  }

  .follow-header {
    font-size: 16px;
  }

  div.icons,
  div.icons > * {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .footer {
    &--one {
      padding: 150px 0 50px 0;
    }

    &--two {
      flex-direction: column;
    }

    .footer-policy {
      flex-direction: column;
      gap: 1rem;
    }

    .space-top {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    &--one {
      padding: 50px 0 50px 0;
    }
  }
}
