.whyUs,
.programmes,
.payment h3 {
  margin-top: 50px;
}

.whyUs li {
  list-style: disc;
  margin-left: 30px;
  font-weight: 300;
  font-size: 17px;
  line-height: 35px;
}
.payment li {
  list-style: disc;
  margin-left: 30px;
  font-weight: 300;
  font-size: 17px;
  line-height: 35px;
}

.custom-list {
  margin-left: 30px;
  font-weight: 300;
  font-size: 17px;
  line-height: 35px;
}

.programGroup-title {
  font-size: 16px;
}

.program-title {
  margin-top: 40px;
}
