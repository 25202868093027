@import "../variables";

.card_container {
    height: 300px;
    max-width: 250px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
}

.card_group_container {
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card_title {
    font-weight: 600;
    font-size: 15px;
}

.card_content {
    font-size: 13px;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.content_price {
    color: $vlb-400-color;
}

.about_points {
    padding-top : 2rem;
    color : white;
    display: flex;
}

.about_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
}

.about_details h4 {
    margin-bottom: 20px;
}